import React from 'react';
import StockCardGridComponent from '../../Global/Layouts/StockCardGrid';
import { StickyLabel } from '../../Global/StyledComponents/UserFormStyledComponents';
import StockCardView from '../../Global/Views/StockCardView/StockCardView';
import Loading from '../../Global/Components/LoadingSpinner';
import { formatDateYear } from '../../Controllers/utils';

const MoversSection = ({ tickerList, afterhoursTickerList, loadingAfterhours }) => {
    return (
        <StockCardGridComponent height={`100%`}>
            {afterhoursTickerList?.length > 0 ?
                <>
                    <StickyLabel>
                        After Hours Movers
                    </StickyLabel>
                    {loadingAfterhours ?
                        <Loading /> :
                        <StockCardView tickerList={afterhoursTickerList} />
                    }
                </> :
                null}
            <>
                <StickyLabel>
                    {`${formatDateYear(new Date().getTime())}`}
                </StickyLabel>
                <StockCardView tickerList={tickerList} />
            </>

        </StockCardGridComponent>
    );
};

export default MoversSection;
