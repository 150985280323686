import { useEffect, useRef, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import supabase from '../../Controllers/utils/supabaseClient';
import {
    FormWrapper,
    Form,
    Header,
    Input,
    MobileFormWrapper,
    StyledButton,
    StyledHyperLink,
    SubmitButton,
    SuccessMessage,
    WarningText,
    SignUpContainer,
} from '../../Global/StyledComponents/UserFormStyledComponents';
import { WhiteTextGlassLogo } from '../../Global/Logos/Logos';


const StyledList = styled.ul`
    height: auto;
    margin: 0;
    padding: 0 20px;
    padding-top: 20px;
`;

const AddNewUserMonitor = () => {
    const containerRef = useRef(null);
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(!firstName || !lastName || !email);
    const [success, setSuccess] = useState(false);
    const [trialLength, setTrialLength] = useState(null);

    useEffect(() => {
        setDisabled(!firstName || !lastName || !email)
    }, [firstName, lastName, email]);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setError(false);
    };

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleTrialLengthChange = (e) => {
        setTrialLength(parseInt(e.target.value));
    };

    const handleScroll = () => {
        containerRef?.current?.scrollIntoView({
            block: 'end',
            behavior: "smooth",
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisabled(true);

        const { data, error } = await supabase.functions.invoke('addUser', {
            body: {
                firstName,
                lastName,
                trialLength,
                userEmail: email.replace(/(\+.+@)/g, '@'),
            },
        });

        if (error) {
            setError(true);
            setErrorMessage('There was an error creating the customer. Please try again.');
            setDisabled(false);

            return;
        }

        const { message, ok } = JSON.parse(data)
        
        if (ok) {
            rewardful('convert', { email: email.replace(/(\+.+@)/g, '@') });
            setSuccess(true);
            setDisabled(false);

            return;
        }

        if (!ok) {
            setError(true);
            setErrorMessage(message);
            setDisabled(false);

            return;
        }
    };

    useEffect(() => {
        containerRef?.current?.scrollIntoView({
            block: 'start',
        });
    }, [])

    return (
        <SignUpContainer isMobile={isMobile && !isTablet} ref={containerRef} signUp>
            <StyledButton
                onClick={() => navigate('/login')}
                style={{
                    color: 'white',
                    padding: '10px',
                    position: 'absolute',
                    top: 0,
                    left: '10px',
                }}
            >
                Back to Login
            </StyledButton>
            <MobileFormWrapper isMobile={isMobile && !isTablet}>
                <FormWrapper isMobile={isMobile && !isTablet}>
                    <WhiteTextGlassLogo title="Market Reader logo" />
                    {!success &&
                        <>
                            <Header>
                                Enter User Details to Add New User
                            </Header>
                            <Form onSubmit={handleSubmit}>
                                <Input
                                    className="login"
                                    onChange={handleFirstNameChange}
                                    onClick={handleScroll}
                                    placeholder="First Name"
                                    type="text"
                                    value={firstName}
                                />
                                <Input
                                    className="login"
                                    onChange={handleLastNameChange}
                                    onClick={handleScroll}
                                    placeholder="Last Name"
                                    type="text"
                                    value={lastName}
                                />
                                <Input
                                    className="login"
                                    onChange={handleEmailChange}
                                    onClick={handleScroll}
                                    placeholder="Email"
                                    type="email"
                                    value={email}
                                />
                                <Input
                                    className="login"
                                    onChange={handleTrialLengthChange}
                                    onClick={handleScroll}
                                    placeholder="Trial Length (Default: 30 days)"
                                    type="number"
                                    value={trialLength}
                                />
                                {error &&
                                    <>
                                        <WarningText>
                                            {errorMessage}
                                        </WarningText>
                                        <br />
                                    </>
                                }
                                <SubmitButton disabled={disabled} type="submit">
                                    Add User
                                </SubmitButton>
                            </Form>
                        </>
                    }
                    {success &&
                        <>
                            <Header><br />User Successfully Added!</Header>
                            <SuccessMessage>
                                Feel free to reach out to the user with their credentials.
                            </SuccessMessage>
                            <StyledList>
                                <div>
                                    User Email: {email}
                                </div>
                            </StyledList>
                        </>
                    }
                </FormWrapper>
                <SuccessMessage>
                    Questions? Contact&nbsp;
                    <StyledHyperLink href = "mailto: support@marketreader.com">support@marketreader.com</StyledHyperLink>
                </SuccessMessage>
            </MobileFormWrapper>
        </SignUpContainer>
    );
};

export default AddNewUserMonitor;
