import styled from 'styled-components';
import moment from 'moment';
import { formatTime, formatReturn } from '../../../../Controllers/utils';
import { AppColors } from '../../../StyledComponents/Graphics';
import { ChartIcon } from '../../../Icons/Icons';
import { DataOutputContainer } from '../../../StyledComponents/AppStyledComponents';
import { Line } from '../../../StyledComponents/AppStyledComponents';
import { isMobile, isTablet } from 'react-device-detect';

export const FeedContainer = styled(DataOutputContainer)`
    border: 1px solid ${AppColors.MRNAVY40};
    background-color: ${AppColors.MRNAVY20};
`;

export const FeedItem = styled.div`
    width: 100%;
    cursor: pointer;
    display: flex;
    margin: 0px;
    padding: 10px;
`;

export const Timestamp = styled.span`
    color: ${AppColors.MRLITE80};
`;

const NewsFeed = ({ feed, handleOpenChart }) => {

    const createFeedItems = () => {
        return (
            feed.map((item, index) => (
                    <>
                        <FeedItem key={item + index} onClick={() => (!isMobile || isTablet) ? handleOpenChart(item.symbol) : null}>
                            <div>
                                <ChartIcon style={{ margin: '5px' }} />
                            </div>
                            <p style={{ margin: 0 }}>
                                <b>{item.symbol} {formatReturn(item.date_return_oc)} [{formatTime(item.insert_timestamp)}]: </b>
                                {item.headline}&nbsp;
                                <Timestamp>({moment(item.insert_timestamp).fromNow()})</Timestamp>
                            </p>
                        </FeedItem>
                        <Line />
                    </>
                ))
        )
    };

    return (
        <FeedContainer>
            {createFeedItems()}
        </FeedContainer>
    );
};

export default NewsFeed;