import React, { createContext, useContext, useEffect, useState } from 'react';
import { datadogLogs } from '@datadog/browser-logs';

import { DefaultSort } from '../../Global/constants';
import useFetchAllWatchlistTickers from '../hooks/useFetchAllWatchlistTickers';
import useFetchAssets from '../hooks/useFetchAssets';
import useFetchCardSize from '../hooks/useFetchCardSize';
import useFetchFilterOptions from '../hooks/useFetchFilterOptions';
import useFetchSavedScreeners from '../hooks/useFetchSavedScreeners';
import useFetchWatchlists from '../hooks/useFetchWatchlists';
import useFetchViewStyle from '../hooks/useFetchViewStyle';
import useFetchMacroSummary from '../hooks/useFetchMacroSummary';
import useFetchMacroSummaries from '../hooks/useFetchMacroSummaries';
import useFetchNotificationSettings from '../hooks/useFetchNotificationSettings';
import useFetchNewsFeedAndAlerts from '../hooks/useFetchNewsFeedAndAlerts';
import { AuthContext } from './auth';

datadogLogs.init({
	clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
	site: 'datadoghq.com',
	service: 'marketreader-frontend',
	env: process.env.NODE_ENV,
	forwardErrorsToLogs: false,
	sessionSampleRate: 100,
});

export const StateContext = createContext();

export const StateProvider = ({ children, values }) => {
	const logError = (message, context, error) =>
		datadogLogs.logger.error(message, context, error);
	const [isLoading, setIsLoading] = useState(true);
	const [activeWatchlistButton, setActiveWatchlistButton] = useState({
		add: false,
		edit: false,
		del: false,
	});
	const [cardData, setCardData] = useState({});
	const [chartInfo, setChartInfo] = useState(null);
	const [chartTicker, setChartTicker] = useState('');
	const [chartTimestamp, setChartTimeStamp] = useState(null);
    const [chartType, setChartType] = useState('D');
	const [currentScreenerPage, setCurrentScreenerPage] = useState(1);
	const [currentWatchlistPage, setCurrentWatchlistPage] = useState(1);
	const development = ['dev', 'localhost'].some((env) =>
		window.location.href.includes(env)
	);
	const [etfConstituents, setEtfConstituents] = useState(null);
	const [etfConstituentsTemp, setEtfConstituentsTemp] = useState(null);
	const [expandedViewTicker, setExpandedViewTicker] = useState('SPY');
	const [filter, setFilter] = useState(null);
	const [loadingExport, setLoadingExport] = useState(false);
	const [monthlySummaryTimestamp, setMonthlySummaryTimestamp] = useState(null);
	const [offSet, setOffset] = useState(0);
	const [refreshRate, setRefreshRate] = useState(null);
	const [sectorOptions, setSectorOptions] = useState(null);
	const [searching, setSearching] = useState(false);
	const [selectedScreener, setSelectedScreener] = useState(null);
	const [selectedWatchlist, setSelectedWatchlist] = useState(null);
	const [showFeed, setShowFeed] = useState(true);
	const [showAddToWatchlistPopup, setShowAddToWatchlistPopup] = useState(false);
	const [showChart, setShowChart] = useState(false);
	const [showChartInfoWindow, setShowChartInfoWindow] = useState(false);
	const [showConfirmationWindow, setShowConfirmationWindow] = useState({
		handleCancel: null,
		handleConfirmation: null,
		headline: null,
		show: false,
	});
	const [showCopyWatchlistPopup, setShowCopyWatchlistPopup] = useState(false);
	const [showCorrelatedAssetsPopup, setShowCorrelatedAssetsPopup] =
		useState(false);
	const [showErrorWindow, setShowErrorWindow] = useState(false);
	const [showEtfConstituentsPopup, setShowEtfConstituentsPopup] =
		useState(false);
	const [showExpandedView, setShowExpandedView] = useState(false);
	const [showExportPopUp, setShowExportPopUp] = useState(false);
	const [showIndustryPeers, setShowIndustryPeers] = useState(false);
	const [showManageSubscriptionPopUp, setShowManageSubscriptionPopUp] =
		useState(false);
	const [showMonthlySummary, setShowMonthlySummary] = useState(false);
	const [showNotificationWindow, setShowNotificationWindow] = useState(false);
	const [showSaveScreenerPopup, setShowSaveScreenerPopup] = useState(false);
	const [showWatchlistPopup, setShowWatchlistPopup] = useState(false);
    const [showWatchlistWindow, setShowWatchlistWindow] = useState(false);
	const [showWelcomeWindow, setShowWelcomeWindow] = useState(false);
	const [sort, setSort] = useState(DefaultSort);
	const [sortWatchlistsByReturn, setSortWatchlistsByReturn] = useState(false);
	const [tickerList, setTickerList] = useState([]);
	const [visibleCards, setVisibleCards] = useState({});
	const [feedbackData, setFeedbackData] = useState(null);
	const [headlinesReviewed, setHeadlinesReviewed] = useState({});
	const [summariesReviewed, setSummariesReviewed] = useState({});
	const [summaryInReview, setSummaryInReview] = useState(false);
	const [showFeedbackModal, setShowFeedbackModal] = useState(false);

	const { user } = useContext(AuthContext);

	const {
		notificationSettings,
		notificationsLoading,
		setNotificationSettings,
	} = useFetchNotificationSettings({ logError });
	const {
		fetchAllWatchlistTickers,
		watchlistTickers,
		watchlistTickersLoading,
	} = useFetchAllWatchlistTickers({ logError, setShowErrorWindow });

	const {
		assets,
		assetKeys,
		assetNames,
		assetsLoading,
		assetTickers,
		chartSymbols,
		fullNameKey,
	} = useFetchAssets({ logError, setShowErrorWindow });
	const {
		cardDimensions,
		cardSizeLoading,
		setCardDimensions,
		setSliderValue,
		sliderValue,
	} = useFetchCardSize({ logError });
	const {
		feed,
		handleSetLastChecked,
		newAlerts,
		newsLoading,
		setFeed,
		setNewAlerts,
		watchlistAlerts,
	} = useFetchNewsFeedAndAlerts({
		logError,
		notificationSettings,
		user,
		watchlistTickers,
	});
	const { filtersLoading, filterOptions } = useFetchFilterOptions({
		filter,
		logError,
	});
	const { macroSummaries, macroSummariesLoading } = useFetchMacroSummaries({
		logError,
	});
	const { macroSummary, macroSummaryLoading } = useFetchMacroSummary({
		logError,
	});
	const { savedScreeners, screenersLoading, fetchAllSavedScreeners } =
		useFetchSavedScreeners({ logError });
	const { viewStyle, viewStyleLoading, setViewStyle } = useFetchViewStyle({
		logError,
	});
	const { watchlists, watchlistsLoading, fetchAllWatchlists } =
		useFetchWatchlists({ logError, setShowErrorWindow });

	useEffect(() => {
		if (
			!assetsLoading &&
			!cardSizeLoading &&
			!filtersLoading &&
			!macroSummariesLoading &&
			!macroSummaryLoading &&
			!newsLoading &&
			!notificationsLoading &&
			!screenersLoading &&
			!viewStyleLoading &&
			!watchlistsLoading &&
			!watchlistTickersLoading
		) {
			setIsLoading(false);
		}
	}, [
		assetsLoading,
		cardSizeLoading,
		filtersLoading,
		macroSummariesLoading,
		macroSummaryLoading,
		newsLoading,
		notificationsLoading,
		screenersLoading,
		viewStyleLoading,
		watchlistsLoading,
		watchlistTickersLoading,
	]);

	const state = {
		activeWatchlistButton,
		assets,
		assetKeys,
		assetNames,
		assetTickers,
		cardData,
		cardDimensions,
		chartInfo,
		chartSymbols,
		chartTicker,
		chartTimestamp,
        chartType,
		currentScreenerPage,
		currentWatchlistPage,
		development,
		etfConstituents,
		etfConstituentsTemp,
		expandedViewTicker,
		feed,
		fetchAllSavedScreeners,
		fetchAllWatchlists,
		fetchAllWatchlistTickers,
		filter,
		filterOptions,
		fullNameKey,
		handleSetLastChecked,
		isLoading,
		loadingExport,
		logError,
		macroSummaries,
		macroSummary,
		monthlySummaryTimestamp,
		newAlerts,
		notificationSettings,
		offSet,
		refreshRate,
		savedScreeners,
		sectorOptions,
		searching,
		selectedScreener,
		selectedWatchlist,
		setActiveWatchlistButton,
		setCardData,
		setCardDimensions,
		setChartInfo,
		setChartTicker,
		setChartTimeStamp,
        setChartType,
		setCurrentScreenerPage,
		setCurrentWatchlistPage,
		setEtfConstituents,
		setEtfConstituentsTemp,
		setExpandedViewTicker,
		setFeed,
		setFilter,
		setLoadingExport,
		setMonthlySummaryTimestamp,
		setNewAlerts,
		setNotificationSettings,
		setOffset,
		setRefreshRate,
		setSectorOptions,
		setSearching,
		setSelectedScreener,
		setSelectedWatchlist,
		setShowAddToWatchlistPopup,
		setViewStyle,
		setShowChart,
		setShowChartInfoWindow,
		setShowConfirmationWindow,
		setShowCopyWatchlistPopup,
		setShowCorrelatedAssetsPopup,
		setShowErrorWindow,
		setShowEtfConstituentsPopup,
		setShowExpandedView,
		setShowExportPopUp,
		setShowIndustryPeers,
		setShowManageSubscriptionPopUp,
		setShowMonthlySummary,
		setShowNotificationWindow,
		setShowSaveScreenerPopup,
		setShowWatchlistPopup,
        setShowWatchlistWindow,
		setShowWelcomeWindow,
		setShowFeed,
		setSliderValue,
		setSort,
		setSortWatchlistsByReturn,
		setTickerList,
		setVisibleCards,
		showAddToWatchlistPopup,
		viewStyle,
		showChart,
		showChartInfoWindow,
		showConfirmationWindow,
		showCopyWatchlistPopup,
		showCorrelatedAssetsPopup,
		showErrorWindow,
		showEtfConstituentsPopup,
		showExpandedView,
		showExportPopUp,
		showIndustryPeers,
		showManageSubscriptionPopUp,
		showMonthlySummary,
		showNotificationWindow,
		showSaveScreenerPopup,
		showWatchlistPopup,
        showWatchlistWindow,
		showWelcomeWindow,
		showFeed,
		sliderValue,
		sort,
		sortWatchlistsByReturn,
		tickerList,
		...values,
		visibleCards,
		watchlistAlerts,
		watchlists,
		watchlistTickers,
		feedbackData,
		setFeedbackData,
		headlinesReviewed,
		setHeadlinesReviewed,
		summariesReviewed,
		setSummariesReviewed,
		summaryInReview,
		setSummaryInReview,
		showFeedbackModal,
		setShowFeedbackModal,
	};

	return (
		<StateContext.Provider value={state}>{children}</StateContext.Provider>
	);
};
