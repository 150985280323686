import { useEffect, useState } from'react';
import supabase from '../utils/supabaseClient';

const useFetchFilterOptions = ({ filter, logError }) => {
    const [filterOptions, setFilterOptions] = useState({});
    const [filtersLoading, setFiltersLoading] = useState(true);
    const [options, setOptions] = useState(null);

    const fetchFilterOptions = async ({ retry = true }) => {
        if (!options) {
            const res = await supabase.from('filter_options').select('*');
            if (res?.error || !res?.data || !Array.isArray(res.data) || res.data.length === 0) {
            const error = new Error(res?.error?.message || 'No data received');
            logError(error.message, undefined, error);

            if (retry) {
                fetchFilterOptions({ retry: false });
                return;
            }

            setFiltersLoading(false);
            return;
        }

            const data = res?.data[0].options;
            setOptions(data);

            const sectorOptions = data?.sector_options.map(option => ({
                label: `${option.gsector}${option.gsubind ? ' - ' + option.gsubind : ''}`,
                value: `gsector=${"'" + option.gsector + "'"}${option.gsubind ? ' AND ' + 'gsubind=' + "'" + option.gsubind + "'" : ''}`,
                assetTypes: option.asset_types,
            }));
    
            setFilterOptions({
                assetType: {
                    label: 'Asset Types',
                    values: data?.asset_type_options,
                },
                sectorOptions: {
                    label: 'Sector and Sub-Industry',
                    values: sectorOptions,
                },
                countryCodes: {
                    label: 'Country',
                    values: data?.country_code_options,
                },
            });
        } else {
            const sectorOptions =  options?.sector_options.map(option => ({
                label: `${option.gsector}${option.gsubind ? ' - ' + option.gsubind : ''}`,
                value: `gsector=${"'" + option.gsector + "'"}${option.gsubind ? ' AND ' + 'gsubind=' + "'" + option.gsubind + "'" : ''}`,
                assetTypes: option.asset_types,
            }));

            const filtered = filter?.assetType ? sectorOptions.filter(({ assetTypes }) => assetTypes.some(assetType => filter.assetType.includes(assetType))) : sectorOptions;
    
            setFilterOptions({
                assetType: {
                    label: 'Asset Types',
                    values: options?.asset_type_options,
                },
                sectorOptions: {
                    label: 'Sector and Sub-Industry',
                    values: filtered,
                },
                countryCodes: {
                    label: 'Country',
                    values: options?.country_code_options,
                },
            });
        }

        setFiltersLoading(false);
    };

    useEffect(() => {
        fetchFilterOptions({ retry: true });
    }, [filter?.assetType]);

    return {
        filtersLoading,
        filterOptions,
    };
};

export default useFetchFilterOptions;
