import { useContext } from "react";
import { isMobile, isTablet } from 'react-device-detect';

import AddToWatchlistForm from "../../Global/Components/AddToWatchlistForm";
import { AuthContext } from "../../Controllers/context/auth";
import ChartInfoWindow from './components/ChartInfoWindow';
import ConfirmationWindow from "./components/ConfirmationWindow";
import CorrelatedAssestsPopUp from "./components/CorrelatedAssetsPopUp";
import ErrorWindow from "./components/ErrorWindow";
import EtfConstituentsPopUp from "./components/EtfConstituentsPopUp";
import ExportPopUp from "./components/ExportPopUp";
import IndustryPeersPopUp from "./components/IndustryPeersPopUp";
import Modal from "./components/Modal";
import ModalChartContainer from "./components/ModalChartContainer";
import MonthlySummary from "./components/MonthlySummary";
import NotificationWindow from './components/NotificationWindow';
import SavedScreenersPopUp from "./components/SavedScreenersPopUp";
import { StateContext } from "../../Controllers/context/state";
import WatchlistPopup from "./components/WatchlistPopup";
import WelcomeWindow from "./components/WelcomeWindow";
import ManageSubscriptionPopUp from "./components/ManageSubscriptionPopUp";
import { FeedbackModal } from "./components/FeedbackModal";

const Modals = () => {
    const { session } = useContext(AuthContext);
    const {
        assets,
        chartTicker,
        customerProfile,
        development,
        expandedViewTicker,
        fullNameKey,
        selectedWatchlist,
        setShowAddToWatchlistPopup,
        setShowChart,
        setShowChartInfoWindow,
        setShowConfirmationWindow,
        setShowCopyWatchlistPopup,
        setShowCorrelatedAssetsPopup,
        setShowErrorWindow,
        setShowEtfConstituentsPopup,
        setShowExportPopUp,
        setShowFeedbackModal,
        setShowIndustryPeers,
        setShowManageSubscriptionPopUp,
        setShowMonthlySummary,
        setShowNotificationWindow,
        setShowSaveScreenerPopup,
        setShowWatchlistPopup,
        setShowWatchlistWindow,
        setShowWelcomeWindow,
        showAddToWatchlistPopup,
        showChart,
        showChartInfoWindow,
        showConfirmationWindow,
        showCopyWatchlistPopup,
        showCorrelatedAssetsPopup,
        showErrorWindow,
        showEtfConstituentsPopup,
        showExportPopUp,
        showFeedbackModal,
        showIndustryPeers,
        showManageSubscriptionPopUp,
        showMonthlySummary,
        showNotificationWindow,
        showSaveScreenerPopup,
        showWatchlistPopup,
        showWatchlistWindow,
        showWelcomeWindow,
    } = useContext(StateContext);

    return (
        <>
            {showAddToWatchlistPopup &&
                <Modal onClose={setShowAddToWatchlistPopup} width={'400px'} >
                    <AddToWatchlistForm />
                </Modal>
            }
            {showChart &&
                <Modal onClose={setShowChart} height={'100%'}>
                    <ModalChartContainer
                        assets={assets}
                        development={development}
                        fullNameKey={fullNameKey}
                        session={session}
                        setShowChart={setShowChart}
                        symbol={chartTicker}
                    />
                </Modal>
            }
            {showChartInfoWindow &&
                <Modal onClose={setShowChartInfoWindow} width={'500px'}>
                    <ChartInfoWindow setShowChartInfoWindow={setShowChartInfoWindow} />
                </Modal>
            }
            {showConfirmationWindow.show &&
                <Modal onClose={setShowConfirmationWindow} width={'fit-content'}>
                    <ConfirmationWindow
                        handleCancel={showConfirmationWindow.handleCancel}
                        handleConfirm={showConfirmationWindow.handleConfirm}
                        headline={showConfirmationWindow.headline}
                        subheading={showConfirmationWindow.subheading}
                    />
                </Modal>
            }
            {showCopyWatchlistPopup &&
                <Modal onClose={setShowCopyWatchlistPopup} width={'400px'} >
                    <AddToWatchlistForm createNew values={selectedWatchlist?.data} />
                </Modal>
            }
            {showCorrelatedAssetsPopup &&
                <Modal onClose={setShowCorrelatedAssetsPopup}>
                    <CorrelatedAssestsPopUp />
                </Modal>
            }
            {showErrorWindow &&
                <Modal onClose={setShowErrorWindow} width={'450px'}>
                    <ErrorWindow />
                </Modal>
            }
            {showEtfConstituentsPopup &&
                <Modal onClose={setShowEtfConstituentsPopup}>
                    <EtfConstituentsPopUp />
                </Modal>
            }
            {showExportPopUp &&
                <Modal onClose={setShowExportPopUp} width={'450px'}>
                    <ExportPopUp />
                </Modal>
            }
            {showFeedbackModal &&
                <Modal onClose={setShowFeedbackModal} width={'450px'}>
                    <FeedbackModal />
                </Modal>
            }
            {showIndustryPeers &&
                <Modal onClose={setShowIndustryPeers}>
                    <IndustryPeersPopUp />
                </Modal>
            }
            {showManageSubscriptionPopUp &&
                customerProfile?.subscriptions?.[0]?.status === 'trialing' &&
                (Math.ceil((customerProfile?.subscriptions?.[0]?.trial_end * 1000 - Date.now()) / (1000 * 60 * 60 * 24)) <= 7) &&
                    <Modal onClose={setShowManageSubscriptionPopUp} image width={'fit-content'} >
                        <ManageSubscriptionPopUp setShowManageSubscriptionPopUp={setShowManageSubscriptionPopUp} />
                    </Modal>
            }
            {showMonthlySummary &&
                <Modal onClose={setShowMonthlySummary} width={(!isMobile || isTablet) ? '70vw' : '95vw'}>
                    <MonthlySummary expandedViewTicker={expandedViewTicker} session={session}/>
                </Modal>
            }
            {showNotificationWindow &&
                <Modal onClose={setShowNotificationWindow} width={'450px'} >
                    <NotificationWindow setShowNotificationWindow={setShowNotificationWindow} />
                </Modal>
            }
            {showSaveScreenerPopup &&
                <Modal onClose={setShowSaveScreenerPopup} width={'400px'} >
                    <SavedScreenersPopUp />
                </Modal>
            }
            {showWatchlistPopup &&
                <Modal onClose={setShowWatchlistPopup} width={'400px'} >
                    <WatchlistPopup />
                </Modal>
            }
            {showWatchlistWindow && 
                <Modal onClose={setShowWatchlistWindow} width={'400px'} >
                    <AddToWatchlistForm createNew />
                </Modal>
            }
            {showWelcomeWindow &&
                <Modal onClose={setShowWelcomeWindow} image width={'fit-content'} >
                    <WelcomeWindow assets={assets} setShowWelcomeWindow={setShowWelcomeWindow} />
                </Modal>
            }
        </>
    )
};

export default Modals;
